import './coursestatus.scss';
import './matches.scss';
import './match.scss';

import * as dompack from 'dompack';

import $ from 'jquery';

dompack.onDomReady(() => {

  $('.p-matches__month').on('click', function() {
    $('.p-matches__month').children().removeClass('p-matches__monthname--current');
    $('.p-matches__matches-container').children().hide();
    $(this).children().addClass('p-matches__monthname--current');
    let monthId = $(this).data('id');
    $('.p-matches__matches-container').find(`div[data-id="${monthId}"]`).show();
  });

});
