require('./login.scss');

import * as dompack from 'dompack';

import $ from 'jquery';
window.jQuery = $;
require('./garlic.min.js');

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-pwgolf-login'))
    return;
});
